import React from 'react';
import styled from '@emotion/styled';
import { useIntl, FormattedMessage } from 'react-intl';
import SEO from '../../components/SEO';
import Layout from '../../components/Layout';
import Heading from '../../components/Reimbursement/Heading';
import { ReactComponent as HowTo } from '../../assets/images/reimbursement/how-to.svg';
import { Link } from 'gatsby';

const Container = styled.div`
  padding-left: 1rem;
  padding-right: 1rem;
  margin-left: auto;
  margin-right: auto;
  max-width: 920px;
`;

export default function Reimbursement() {
  const intl = useIntl();

  const renderContent = () => {
    if (intl.locale === 'de') {
      return (
        <p>
          (Berlin, 27.12.21) Cara Care für Reizdarm, die erste
          verschreibungsfähige digitale Therapie für das Reizdarmsyndrom,
          entwickelt von der HiDoc Technologies GmbH, ist seit dem 27. Dezember
          2021 als Digitale Gesundheitsanwendung (DiGA) zugelassen. Damit können
          Ärzte/-innen das Medizinprodukt zukünftig Betroffenen mit Reizdarm
          verordnen. Alle gesetzlichen Krankenkassen übernehmen dabei nach dem
          Digitale-Versorgung-Gesetz (DVG) die Kosten ohne Zuzahlung.
          <br />
          <br />
          Laut Studiendaten leiden in Deutschland etwa 17 Prozent am
          Reizdarmsyndrom. Damit zählt das Reizdarmsyndrom zu den häufigsten
          Störungen des Verdauungssystems und wirkt sich nicht nur auf das
          körperliche Wohlbefinden, sondern oft auch auf die seelische
          Gesundheit aus. Viele der Betroffenen erhalten keine korrekte Diagnose
          und Therapie. Im deutschen Gesundheitssystem entstehen durch das
          Reizdarmsyndrom immense Kosten. Diese werden durch Doppeldiagnostik,
          die Verschreibung teurer, nicht immer wirksamer Medikamente und
          häufige Arztbesuche verursacht.
          <br />
          <br />
          Daten einer Pilotstudie, die im Rahmen der Zulassung durch das BfArM
          durchgeführt wurde, sind vielversprechend: Cara Care für Reizdarm
          erlaubt es Patienten/-innen, durch Ernährungsempfehlungen,
          audio-geführte Hypnose und verhaltenstherapeutische Übungen eine
          deutliche Verbesserung der Lebensqualität zu erreichen. Gleichzeitig
          kann bei einem großen Teil der Betroffenen die Symptomschwere durch
          die multimodale Therapie signifikant gesenkt werden. Das
          Therapiekonzept von Cara Care für Reizdarm setzt auf personalisierte
          Medizin und steht im Einklang mit den aktuellen S3-Leitlinien von
          2021.
          <br />
          <br />
          Gesundheits-Apps haben sich besonders bei der Behandlung chronischer
          Krankheiten bewährt. Seit Oktober 2020 gibt es die sogenannten “Apps
          auf Rezept“ – softwarebasierte Medizinprodukte, die vom Bundesinstitut
          für Arzneimittel und Medizinprodukte (BfArM) sorgfältig geprüft wurden
          und klare Qualitätsvoraussetzungen erfüllen müssen, um von den
          gesetzlichen Krankenkassen erstattet zu werden. Immer mehr private
          Krankenkassen ziehen aktuell nach und erstatten Apps auf Rezept
          ebenfalls.
          <br />
          <br />
          Jesaja Brinkmann, Mitgründer und Geschäftsführer bei HiDoc
          Technologies, erklärt: “Es war schon immer unser Traum, dass
          Krankenkassen eine holistische Reizdarm-Therapie voll erstatten.
          Millionen von Betroffenen wurden bisher mit ihren unangenehmen
          Beschwerden komplett allein gelassen – und das in einem der besten
          Gesundheitssysteme der Welt. Ab sofort gibt es endlich
          flächendeckenden und kostenlosen Zugang zu der multimodalen Therapie,
          die in den Leitlinien schon lange empfohlen wird. Betroffene können
          von nun an bequem per App rund um die Uhr auf ihr personalisiertes
          Therapieprogramm zugreifen, um so Schritt für Schritt ein besseres
          Leben zu führen.“
          <br />
          <br />
          Prof. Dr. med. Martin Storr, Facharzt für Innere Medizin und
          Gastroenterologie und Mitglied in nationalen und europäischen
          Leitlinienkommissionen, erklärt: “Ich freue mich über die Zulassung
          von Cara Care für Reizdarm als verschreibbare Digitale
          Gesundheitsanwendung. Klinische Studien belegen, dass
          Ernährungsprogramme wie die Low-FODMAP-Ernährung bei einem weit
          verbreiteten Krankheitsbild wie dem Reizdarmsyndrom signifikante
          Verbesserungen der Lebensqualität ermöglichen können. Um so besser,
          wenn Patienten hier digital unterstützt und motiviert werden können.“
          <br />
          <br />
          “Wir freuen uns sehr, dass wir den rigorosen Zulassungsprozess
          erfolgreich durchschreiten konnten. Bei der Entwicklung von Cara Care
          für Reizdarm war uns insbesondere wichtig, Darmgesundheit mit
          innovativen Lösungen zu kombinieren, um Reizdarmpatienten/-innen die
          bestmögliche digitale Therapie zu ermöglichen.”, sagt Felix Köhler,
          Chief Operating Officer bei HiDoc Technologies.
          <br />
          <br />
          Dr. med. André Sommer, Mitgründer und Geschäftsführer bei HiDoc
          Technologies ergänzt: “Wir sind begeistert, unseren Patienten/-innen
          jetzt noch besser helfen zu können. Das knüpft an unsere bisherigen
          Erfolge an: Über 700.000 Downloads in Deutschland und den USA und ein
          internationales App Store Rating von 4,8 sprechen für sich. Wir freuen
          uns über das Vertrauen des Bundesinstituts und unserer Nutzenden!“
          <br />
          <br />
          Cara Care für Reizdarm ist somit ab sofort ärztlich verordnungsfähig
          und in den meisten Medikamentenlisten der PVS zu finden. Die
          Verordnung erfolgt über ein Kassenrezept (Muster 16). Das Rezept muss
          den Produktnamen “Cara Care für Reizdarm”, die Pharmazentralnummer
          (PZN) sowie die Verordnungsdauer von 3 Monaten enthalten.
          <br />
          <br />
          Weitere Informationen zu Cara Care für Reizdarm finden Sie unter:{' '}
          <Link to="https://cara.care/de">https://cara.care/de/</Link>
          <br />
          <br />
          <Heading level={5}>Über HiDoc Technologies GmbH</Heading>
          Das Berliner Digital-Health-Startup HiDoc Technologies GmbH hat mit
          Cara Care für Reizdarm die erste digitale Gesundheitsanwendung (DiGA)
          zur Verwendung beim Reizdarmsyndrom entwickelt, die von Ärzten/-innen
          mit Rezept verschrieben werden kann und höchsten Sicherheitsstandards
          entspricht. Die DiGA wurde vom Bundesinstitut für Arzneimittel und
          Medizinprodukte (BfArM) geprüft und wird seit Dezember 2021 im Rahmen
          des Digitale-Versorgung-Gesetzes (DVG) von allen gesetzlichen
          Krankenkassen erstattet. Cara Care für Reizdarm wurde von
          Ärzten/-innen entwickelt und wird bis heute von einem Team
          medizinischer Experten/-innen geführt.
          Ernährungswissenschaftler/-innen, Psychologen/-innen und weitere
          Spezialisten/-innen machen den Ansatz von Cara Care ganzheitlich.
          Neben Cara Care für Reizdarm hat das Unternehmen noch Medizinprodukte
          gegen Sodbrennen und chronisch entzündliche Darmerkrankungen (Morbus
          Crohn und Colitis Ulcerosa) auf dem Markt. Weitere Therapiegebiete
          sind in der Entwicklung.
          <br />
          <br />
          Der Fokus von Cara Care für Reizdarm liegt auf der Kombination von
          neuesten Forschungserkenntnissen zur Darmgesundheit aus der klinischen
          Praxis mit Behavioral-Design-Ansätzen – personalisiert, motivierend
          und auf dem Smartphone immer verfügbar. Ziel der DiGA ist es, über die
          Analyse der individuellen Symptome einen personalisierten Ansatz zur
          Verbesserung der Lebensqualität und Symptomreduktion anzubieten.
          Studiendaten zeigen, dass 73% der Nutzer*innen eine klinisch relevante
          Verbesserung der Symptome erleben. Gleichzeitig können
          Begleitbeschwerden wie Ängstlichkeit und Depressivität reduziert und
          die Lebensqualität gesteigert werden.
          <br />
          <br />
          CCara Care für Reizdarm ist in Europa bereits als CE-zertifiziertes
          Medizinprodukt der Klasse 1 für die Behandlung von Reizdarm
          zertifiziert und wurde 2020 in die Healthline’s Best Digestive Health
          Apps aufgenommen. Unternehmenssitz der HiDoc GmbH ist Berlin, das
          Unternehmen beschäftigt derzeit 28 Mitarbeiter/-innen.
          <br />
          <br />
          Cara Care für Reizdarm ist im Google Play Store für{' '}
          <Link to="https://play.google.com/store/apps/details?id=com.gohidoc.caraeu&hl=de&gl=US">
            Android
          </Link>{' '}
          und Apple App Store für{' '}
          <Link to="https://apps.apple.com/de/app/cara-care/id1526811241">
            iOS
          </Link>{' '}
          verfügbar. Über die Homepage gibt es nähere Informationen zum
          Verschreibungsprozess und einen Eignungstest für die
          Verschreibbarkeit.
          <br />
          <br />
          Kontakt: Jesaja Brinkmann
          <br />
          Mitgründer und Geschäftsführer
          <br />
          HiDoc Technologies GmbH
          <br />
          Telefon +49-30-54909949
          <br />
          Email presse@cara.care
          <br />
          <Link to="https://cara.care/de/">https://cara.care/de</Link>
        </p>
      );
    }

    return (
      <p>
        (Berlin, 03.01.22) Cara Care for IBS, the first prescription digital
        therapeutic for irritable bowel syndrome (IBS), developed by HiDoc
        Technologies GmbH, will be approved as a digital health application
        (DiGA) as of January 1, 2022. This means physicians can prescribe the
        medical device to patients with IBS in the future. All statutory health
        insurances assume the costs without additional payments under the
        Digital Healthcare Act (DVG).
        <br />
        <br />
        According to epidemiological data, some 17 percent of people in Germany
        suffer from IBS. Hence, IBS constitutes one of the most common
        gastrointestinal disorders and not only affects physical well-being, but
        also mental health in many cases. A large number of patients do not
        receive a correct diagnosis and therapy. IBS results in enormous costs
        for the German healthcare system. This is caused by redundant diagnostic
        procedures, the prescription of expensive medication that is not always
        effective, and regular visits to the doctors.
        <br />
        <br />
        A pilot study could show within the approval process that Cara Care for
        IBS enables patients, through nutritional recommendations, audio-guided
        hypnosis, and behavioral therapy exercises, to achieve a significantly
        improved quality of life. At the same time, a large proportion of
        patients can considerably reduce the severity of symptoms by means of
        multimodal therapy. The therapy concept by Cara Care for IBS utilizes a
        personalized medicine approach and is in line with the most recent
        German S3-guideline from 2021.
        <br />
        <br />
        Health apps have proven their worth particularly when it comes to
        treating chronic diseases. Since October 2020, there have been so-called
        “apps on prescription”– software-based medical devices that were
        carefully assessed by the Federal Institute for Drugs and Medical
        Devices (BfArM) and have to comply with quality requirements in order to
        be reimbursed by the statutory health insurances. An increasing number
        of private health insurances are currently following suit and are
        reimbursing apps on prescription as well.
        <br />
        <br />
        Jesaja Brinkmann, co-founder and co-CEO at HiDoc Technologies, explains,
        “For health insurances to fully reimburse a holistic IBS therapeutic has
        always been a dream of ours. Until now, millions of patients have been
        left to deal with their own unpleasant symptoms in what is one of the
        best healthcare systems worldwide. Extensive and free access to
        multimodal treatment is now finally available, which has long been
        recommended in the guidelines. Patients can now conveniently access
        their personalized digital therapeutic around the clock via an app to
        take steps towards a better life.“
        <br />
        <br />
        Prof. Dr. med. Martin Storr, Specialist for Internal Medicine and
        Gastroenterology and a member of national and European Guideline
        Commissions, explains, “I’m delighted about the approval of Cara Care
        for IBS as a prescribable digital health application. Clinical studies
        prove that nutritional programs such as the low FODMAP diet can lead to
        a significantly improved quality of life for a widespread clinical
        condition such as IBS. And all the better if patients can be digitally
        supported and motivated here.“
        <br />
        <br />
        “We are very happy that we were able to successfully pass the rigorous
        approval process. When developing Cara Care for IBS, it was particularly
        important for us to combine intestinal health with innovative solutions
        to facilitate the best possible digital therapeutic for IBS patients”,
        says Felix Köhler, Chief Operating Officer at HiDoc Technologies.
        <br />
        <br />
        Dr. med. André Sommer, co-founder and co-CEO at HiDoc Technologies goes
        on to say: “We are thrilled that we are now able to provide even better
        help for our patients. It ties in with our previous success: more than
        700,000 downloads in Germany and the USA, and an international App Store
        rating of 4.8 speak for themselves. We are delighted about the trust
        placed in us by the Federal Institute and our users!”
        <br />
        <br />
        Cara Care for IBS can therefore be prescribed by doctors with immediate
        effect and can be found in most of the drugs lists of practice
        management systems. The prescription is issued via the public health
        insurance prescription “Muster 16”. The prescription must include the
        product name “Cara Care for IBS“, the Pharmacy Central Number (PZN) as
        well as the prescription duration of 3 months.
        <br />
        <br />
        Further information on Cara Care for IBS can be found at:{' '}
        <Link to="https://cara.care/">https://cara.care/</Link>
        <br />
        <br />
        <Heading level={5}>About HiDoc Technologies GmbH</Heading>
        With Cara Care for IBS, the Berlin digital health startup HighDoc
        Technologies GmbH has developed the first digital health application
        (DiGA) for use with IBS that can be prescribed by doctors, and meets the
        highest safety standards. The DiGA was approved by the Federal Institute
        for Drugs and Medical Devices (BfArM), and since January 2022 can be
        reimbursed by all statutory health insurances as part of the Digital
        Healthcare Act (DGV). Cara Care for IBS was developed by physicians, and
        it continues to be managed by a team of medical experts. Nutritionists,
        psychologists and specialists endow Cara Care with a holistic approach.
        In addition to Cara Care for IBS, the company also has medical devices
        for heartburn-related conditions and chronic inflammatory bowel diseases
        ( Crohn’s disease and Ulcerative Colitis) on the market. Further
        therapeutic areas are currently being developed.
        <br />
        <br />
        The focus of Cara Care for IBS lies in combining the most innovative
        research knowledge on gastrointestinal health from clinical practice
        with behavioral design approaches – personalized, motivating and always
        available via smartphone. The goal of the DiGA is to analyze individual
        symptoms in order to provide a tailor-made approach for improving
        quality of life and reducing symptoms. Study data show that 73 percent
        of users experience a clinically relevant improvement of symptoms. At
        the same time, concomitant symptoms, such as anxiety and depression, can
        be reduced and quality of life increased.
        <br />
        <br />
        Cara Care for IBS is already certified in Europe as a Class 1 CE-marked
        medical device for the treatment of irritable bowel, and was included in
        the Healthline’s Best Digestive Health Apps in 2020. The company
        headquarters of HiDoc GmbH is in Berlin, and the company currently
        employs 28 people.
        <br />
        <br />
        Cara Care for IBS is available in the Google Play Store for{' '}
        <Link to="https://play.google.com/store/apps/details?id=com.gohidoc.caraeu&hl=de&gl=US">
          Android
        </Link>{' '}
        and Apple App Store for{' '}
        <Link to="https://apps.apple.com/de/app/cara-care/id1526811241">
          iOS
        </Link>
        . The homepage provides more information on the prescription process and
        a suitability test for prescribability.
        <br />
        <br />
        Contact: Jesaja Brinkmann
        <br />
        Co-founder and CEO
        <br />
        HiDoc Technologies GmbH
        <br />
        Telephone +49-30-54909949
        <br />
        Email presse@cara.care
        <br />
        <Link to="https://cara.care/">https://cara.care/</Link>
      </p>
    );
  };
  return (
    <Layout isNewLayout>
      <SEO
        lang={intl.locale}
        title={intl.formatMessage({
          id: 'diga.launch',
          defaultMessage: 'Diga Launch',
        })}
      />
      <HowTo css={{ marginTop: 24, marginBottom: 40 }} />
      <Container css={{ marginBottom: 64 }}>
        <Heading level={2}>
          <FormattedMessage
            id="diga.launch.title"
            defaultMessage="“Cara Care for IBS” approved as a prescription digital therapeutic – German health insurances will reimburse the costs with a medical prescription for 90% of Germans"
          />
        </Heading>
        <br />

        <Heading level={5}>
          <FormattedMessage
            id="diga.launch.subtitle"
            defaultMessage="Patients with irritable bowel syndrome (IBS) gain access to a prescription digital therapeutic. The multimodal treatment is based on nutrition therapy, elements of cognitive-behavioral therapy, and gut-directed hypnosis as recommended by the new German IBS guidelines."
          />
        </Heading>
        <br />
        {renderContent()}
      </Container>
    </Layout>
  );
}
